<template>
  <div class="lottery-detail">
    <!-- 顶部tabbar栏 -->
    <van-nav-bar
      left-arrow
      @click-left="$router.back()"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <template #right>
        <div class="title-content" @click="goRoom()">
          <div class="lottery-left">
            <img class="lottery-img" :src="lotteryData.picture" alt="" />
            <div class="lottery-info">
              <span class="lottery-info-title">{{ lotteryData.title }}</span>
              <div class="lottery-info-expect">
                <!-- <span v-if="lotteryIng.length === 0">The early morning</span> -->
                <span v-if="lotteryIng.length === 0">sắp mở</span>
                <span v-else> Lượt xổ {{ lotteryIng.expect }} </span>
              </div>
            </div>
          </div>
          <div class="lottery-right">
            <div class="lottery-time">
              <van-count-down
                v-if="lotteryEnd > 0"
                :time="lotteryEnd * 1000"
                format="mm : ss"
              />
            </div>
            <div class="lottey-status">
              <!-- <span v-if="lotteryIng.length === 0">Will the lottery</span> -->
              <span v-if="lotteryIng.length === 0">Thời gian</span>
              <span v-else>
                {{ lotteryEnd > 0 ? 'Thời gian' : 'chờ xổ số' }}</span
              >
            </div>
          </div>
        </div>
      </template>
    </van-nav-bar>

    <!-- list 列表 -->
    <div class="lottery-list-title">
      <!-- <van-row>
        <van-col span="10">vấn đề</van-col>
        <van-col span="7">รางวัล</van-col>
        <van-col span="7">kết quả</van-col>
      </van-row> -->
    </div>

    <div class="lottery-list">
      <van-list
        v-model="Listloading"
        :finished="Listfinished"
        finished-text="không còn nữa..."
        @load="ListonLoad"
        loading-text="Đang tải..."
      >
        <van-cell v-for="item in lotteryList" :key="item.id">
          <template #default>
            <div class="lottery-content">
              <div class="lottery-period">
                <div class="expect">{{ item.expect }}</div>
                <div class="code">
                  <template v-for="(code, index1) in openDwd(item.code)">
                    <span
                      v-if="
                        index1 !== openDwd(item.code).length - 1 ||
                        item.status !== '已开奖1'
                      "
                      :key="index1"
                      :class="[
                        item.status === '已开奖1'
                          ? 'hl-item-code-item'
                          : 'item-code-text',
                      ]"
                    >
                      {{ code }}
                    </span>
                    <img
                      class="code-img"
                      v-else
                      :key="'else'+index1"
                      style="width: 0.625rem; height: 0.625rem"
                      :src="
                        require(`@/assets/images/lottery_history/${code}.png`)
                      "
                    />
                    <!-- <span
                      class="lottery-info-code-text"
                      v-for="(code, i) in item.lastinfo.codes ||
                      item.lastinfo.code"
                      :key="i"
                      v-show="item.type !== 'hl'"
                      >{{ code }}</span
                    > -->
                    <!-- <span
                    class="lottery-info-code-text"
                    v-if="item.type === 'hl' && item.code"
                    >{{ substrEvent(item.code) }}</span
                  > -->
                    <!-- <span></span> -->
                  </template>
                </div>
              </div>

              <!-- <div class="lottery-number">
                <div
                  class="item-code-last"
                  :style="{ color: resultColor(item.code)[0].color }"
                >
                  {{ resultColor(item.code)[0].number }}
                </div>
              </div> -->

              <div class="lottery-result">
                <div class="time">
                  {{ (item.opentime * 1000) | formatTimeHandler }}
                </div>
                <!-- <div class="ball-box">
                  <div
                    class="ball"
                    v-for="(ball, index2) in resultColor(item.code)"
                    :key="index2"
                    :style="{ 'background-color': ball.color }"
                  ></div>
                </div> -->
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
      <div class="footer" v-if="isFull">không có thêm thông tin</div>
    </div>
    <div class="purchase-color" @click="goRoom()">Đặt cược ngay</div>
  </div>
</template>

<script>
import { getLotteryInfo, getLotteryList } from '@/api/lottery.js'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      lotteryData: {},
      list: [],
      Listloading: false, // 数据加载完，设置为false，表示加载状态结束
      Listfinished: false, // 数据全部加载完设置为true
      pages: 1,
      lotteryList: [], // 开奖列表数据
      total: 1, // 总开奖列表条数
      lotteryIng: [], // ลอตเตอรี่อยู่ในระหว่างดำเนินการ的数据
      lotteryEnd: 0, // 离开奖时间
    }
  },
  computed: {
    isFull() {
      return this.lotteryList.length === this.total
    },
  },
  watch: {
    lotteryEnd: function (val) {
      if (val < 0 && this.lotteryIng.expect !== '') {
        const also = 10 - Math.abs(val % 10)
        if (also === 2) {
          this.getLotteryInfoHandler()
          this.getLotteryListHandler()
        }
      }
    },
  },
  created() {
    this.lotteryData = this.$store.state.lottery || {}
    this.getLotteryInfoHandler()
    this.getLotteryListHandler()
    this.lotteryEndWatch()
    this.lotteryListWatch()
  },
  mounted() {},

  methods: {
    //   离开奖结束倒计时
    lotteryEndWatch() {
      var that = this
      const timer1 = window.setInterval(() => {
        setTimeout(() => {
          that.lotteryEnd--
        }, 0)
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1)
      })
    },

    //   每隔10s获取开奖列表
    lotteryListWatch() {
      var that = this
      const timer2 = window.setInterval(() => {
        setTimeout(() => {
          that.getLotteryListHandler()
        }, 0)
      }, 10000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer2)
      })
    },

    //   获取当前开奖数据lotteryEnd的处理
    async getLotteryInfoHandler() {
      const {
        data: { data, ret },
      } = await getLotteryInfo({
        type: this.lotteryData.type,
      })
      if (ret === 1) {
        if (data.ing.status === '待开奖') {
          this.lotteryIng = data.ing
          this.lotteryEnd =
            data.ing.endtime - Math.round(new Date().getTime() / 1000)
        }
      }
    },

    // 获取开奖列表数据
    async getLotteryListHandler() {
      this.Listloading = true
      const {
        data: { data, ret },
      } = await getLotteryList({
        type: this.lotteryData.type,
        page: this.pages,
      })
      this.Listloading = false
      this.isLoading = false
      if (ret === 1) {
        this.total = data.total
        const dataLists = data.lists
        if (data.currentPage > 1) {
          for (let i = 0; i < dataLists.length; i++) {
            this.lotteryList.push(dataLists[i])
          }
        } else {
          this.lotteryList = dataLists
        }
      }

      //   数据加载完 Listfinished设置为true
      if (this.lotteryData.length === this.total) {
        this.Listfinished = true
      }
    },

    // 判断是否开奖并返回wait或者code
    openDwd(code) {
      if (code === '') {
        return ['']
      } else {
        return code
      }
    },

    // 判断最后一位数字和颜色,返回数字和颜色
    //   1379绿色  2468红色 5绿色加màu tía色 0红色加紫色
    resultColor(code) {
      const itemOpen = this.openDwd(code)
      const number = itemOpen[itemOpen.length - 1]
      const color = ['#13cc00', '#ff0000', '#b400ff', '#fff']
      let colorStr = [{ number, color: '', img: '' }]
      if (number === '') {
        colorStr = [{ number: null, color: '' }]
      } else if (parseInt(number) === 5) {
        colorStr = [
          { number, color: color[0] },
          { number, color: color[2] },
        ]
      } else if (parseInt(number) === 0) {
        colorStr = [
          { number, color: color[1] },
          { number, color: color[2] },
        ]
      } else if (number % 2 === 0) {
        colorStr = [{ number, color: color[1] }]
      } else if (number % 2 !== 0) {
        colorStr = [{ number, color: color[0] }]
      }
      return colorStr
    },

    // 滚动加载数据
    ListonLoad() {
      this.pages++
      this.getLotteryListHandler()
    },

    // // 下拉刷新
    // onRefresh () {
    //   this.getLotteryListHandler()
    // },

    goRoom() {
      this.$router.push({
        path: '/user/order2',
        name: 'userOrder2',
        query: { data: this.lotteryData },
      })
    },
    substrEvent(val) {
      if (val) {
        return val.substr(val.length - 1, 1)
      } else {
        return '/'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.lottery-detail {
  height: 100%;
  background-color: #fff;
  .van-nav-bar {
    position: fixed;
    width: 100%;
    height: 130px;
    // background-color: #ff0000;
    /deep/ .van-icon {
      // color: #fff;
    }
    /deep/ .van-nav-bar__content {
      height: 100%;
      /deep/ .van-icon-arrow-left {
        // color: #fff;
        font-size: 40px;
      }
      .van-nav-bar__right {
        display: flex;
        align-items: center;
        padding: 0;
        .title-content {
          display: flex;
          justify-content: space-between;
          width: 550px;
          height: 105px;
          background-color: #fff;
          border-top-left-radius: 52px;
          border-bottom-left-radius: 52px;
          .lottery-left {
            display: flex;
            //   justify-content: flex-start;
            align-items: center;
            .lottery-img {
              padding: 0 10px;
              width: 90px;
              height: 90px;
            }
            .lottery-info {
              text-align: left;
              font-family: 'PingFang-Regular';
              .lottery-info-title {
                font-weight: normal;
                font-size: 30px;
                color: #ff0000;
              }
              .lottery-info-expect {
                color: #646464;
                font-size: 24px;
              }
            }
          }
          .lottery-right {
            padding-right: 25px;
            flex-direction: column;
            justify-content: center;
            display: flex;
            font-family: 'PingFang-Regular';
            .lottery-time {
              color: #ff0000;
              font-size: 30px;
            }
            .lottey-status {
              color: #646464;
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .van-col {
    text-align: center;
  }
  .lottery-list-title {
    padding: 70px 20px 0;
    height: 60px;
    line-height: 60px;
    // border-bottom: 1px solid #e8e8e8;
    font-family: 'PingFang-Regular';
    font-size: 30px;
    color: #000;
    background-color: #fff;
  }
  .lottery-list {
    height: calc(100% - 230px);
    overflow: auto;
    .lottery-content {
      display: flex;
      justify-content: space-between;
      .lottery-period {
        width: 41%;
        .expect {
          font-family: 'PingFang-Regular';
          font-size: 24px;
          color: #646464;
          line-height: 24px;
          margin-bottom: 20px;
        }
        .code {
          span {
            display: inline-block;
          }
          .code-img {
            vertical-align: bottom;
          }

          .hl-item-code-item {
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin-top: 20px;
            text-align: center;
            margin-right: 8px;
            color: #ffffff;
            font-size: 24px;
            border-radius: 23px;
            background-color: #4800ff;
          }
          .item-code-text {
            color: #dc1323;
            font-size: 28px;
            margin-right: 16px;
          }
          .lottery-info-code-text {
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #ffffff;
            background-color: #dc1323;
            border-radius: 50px;
          }
        }
      }
      .lottery-number {
        text-align: center;
        width: 30%;
        display: flex;
        align-items: flex-end;
        .item-code-last {
          width: 100%;
          text-align: center;
          font-family: 'PingFang-Medium';
          font-size: 32px;
        }
      }
      .lottery-result {
        // width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .time {
          font-family: 'PingFang-Regular';
          line-height: 26px;
          font-size: 24px;
          color: #646464;
        }
        .ball-box {
          display: flex;
          .ball {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 5px;
          }
        }
      }
    }
    .footer {
      margin: 30px 0;
      text-align: center;
      height: 40px;
      line-height: 40px;
      font-size: 30px;
      font-family: 'PingFang-Regular';
    }
  }

  .purchase-color {
    position: fixed;
    bottom: 0;
    height: 100px;
    width: 100%;
    line-height: 100px;
    text-align: center;
    color: #fff;
    background-color: #ff0000;
    font-size: 30px;
    font-family: 'PingFang-Medium';
  }
}
</style>
